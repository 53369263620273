import { Fragment, useEffect, useState } from "react";


function Banner() {
    const logo_url = "https://res.cloudinary.com/ddla464hf/image/upload/v1707485601/GR_bmswqx.png"
    return (
        <div className="banner_container">
            <div className="banner_item_container">
                <div>
                    <div className="banner_logo_container">
                        <img src="/GR_logo_green_simple.png" alt="Logo GetReady" class="banner_logo_app" />
                    </div>
                </div>


                <div className="logo_border">
                    <div className="banner_logo_container">
                        <svg viewBox="0 0 24 24" aria-hidden="true" class="banner_logo"><g><path d="M10.09 3.098L9.72 7h5.99l.39-4.089 1.99.187L17.72 7h3.78v2h-3.97l-.56 6h3.53v2h-3.72l-.38 4.089-1.99-.187.36-3.902H8.78l-.38 4.089-1.99-.187L6.77 17H2.5v-2h4.46l.56-6H3.5V7h4.21l.39-4.089 1.99.187zM14.96 15l.56-6H9.53l-.56 6h5.99z"></path></g></svg>
                    </div>
                </div>

                <div className="logo_border">
                    <div className="banner_logo_container">
                        <svg viewBox="0 0 24 24" aria-hidden="true" class="banner_logo"><g><path d="M3 4.5C3 3.12 4.12 2 5.5 2h13C19.88 2 21 3.12 21 4.5v15c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 22 3 20.88 3 19.5v-15zM5.5 4c-.28 0-.5.22-.5.5v15c0 .28.22.5.5.5h13c.28 0 .5-.22.5-.5v-15c0-.28-.22-.5-.5-.5h-13zM16 10H8V8h8v2zm-8 2h8v2H8v-2z"></path></g></svg>                    
                    </div>
                </div>

                <div className="logo_border_post">
                    <div className="banner_logo_container_post">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="banner_logo_post"><g><path d="M23 3c-6.62-.1-10.38 2.421-13.05 6.03C7.29 12.61 6 17.331 6 22h2c0-1.007.07-2.012.19-3H12c4.1 0 7.48-3.082 7.94-7.054C22.79 10.147 23.17 6.359 23 3zm-7 8h-1.5v2H16c.63-.016 1.2-.08 1.72-.188C16.95 15.24 14.68 17 12 17H8.55c.57-2.512 1.57-4.851 3-6.78 2.16-2.912 5.29-4.911 9.45-5.187C20.95 8.079 19.9 11 16 11zM4 9V6H1V4h3V1h2v3h3v2H6v3H4z"></path></g></svg>
                    </div>
                </div>


            </div>
            
        </div>
    )
}

export default Banner