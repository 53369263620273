import React, { useState, useCallback, useEffect } from 'react';
import '../form.css';

function FormNewTweet() {
  const [dataInit, setDataInit] = useState();
  const [postData, setPostData] = useState({
    _id: '',
    createdAt: '',
    'content.text': '',
    'content.date': '',
    'content.media_type': '',
    'content.is_attachment': false,
    language: '',
    'reaction.comment_count': 0,
    'reaction.like_count': 0,
    'reaction.view_count': 0,
    'reaction.repost_count': 0,
    owner: '',
    author_id: '',
    theme: '',
    'source.url': '',
    'source.probability': 8,
    "source.explanation":'',
    'source.know_more':'',
    masked: false,
    isAttachment: false,
    attachment_file: null,
    newAuthor: false,
    'author_info.name':'',
    'author_info.is_certified': false,
    'author_info.account_since': 2011,
    'author_info.followers': 1000,
    'author_info.following': 100,
    'author_info.owner':'',
    'author_info.agreement':false,
    'author_info.category':'',
    'author_info.profile_type':'',
    profile_picture_file: null,
    background_picture_file: null,
    source_illustration_file: null,

  });



  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    // const [field, subfield] = name.split('.');
    console.log(name, value, type, checked, files)

    if (files) {
        setPostData({ ...postData, [name]: files[0] }); // Gérer le fichier sélectionné
    } else {
        if (name in postData) {
          console.log('here')
          if (type === 'checkbox') {
            setPostData({ ...postData, [name]: checked });
          } else {
            setPostData({ ...postData, [name]: value });
          }
        }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('attachment_file', postData.attachment_file);
    formData.append('profile_picture_file', postData.profile_picture_file)
    formData.append('background_picture_file', postData.background_picture_file)
    formData.append('source_illustration_file', postData.source_illustration_file)

    const appendFormData = (data, parentKey = '') => {
        Object.keys(data).forEach(key => {
          // Vérifiez si la propriété est un objet
          if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null && key !== 'file') {
            appendFormData(data[key], parentKey + key + '.');
          } else {
            // Ajoutez directement les valeurs simples ou convertissez les tableaux/objets en JSON
            formData.append(parentKey + key, typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]);
          }
        });
    };

    appendFormData(postData);

    // Envoyez formData à votre API
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create_post`, {
        method: 'POST',
        body: formData, // Pas besoin de spécifier 'Content-Type' ici; FormData le fait pour vous
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      console.log(data); // Gérer la réponse ici
    } catch (error) {
      console.error('Erreur lors de la soumission:', error);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault(); // Nécessaire pour permettre le drop
  };

  const onDrop= (e, key) => {
    console.log(key)
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setPostData({ ...postData, [key]: files[0] }); 
    }
  };


  const openFileDialog = (elem_key) => {
    document.getElementById(elem_key).click();
  };



  useEffect(() => {
    // Fonction pour charger les données depuis l'API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/newpost_infos`);
        const result = await response.json();
        
        // Mise à jour de l'état avec les données récupérées
        setDataInit(result);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    // Appel de la fonction fetchData au montage du composant
    fetchData();
  }, []); 

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="_id"
        value={postData._id}
        onChange={handleChange}
        placeholder="ID"
      />
      <input
        type="datetime-local"
        name="createdAt"
        value={postData.createdAt}
        onChange={handleChange}
        placeholder="Date de création"
      />
      <fieldset>
        <legend>Contenu:</legend>
        <input
          type="text"
          name="content.text"
          value={postData['content.text']}
          onChange={handleChange}
          placeholder="Texte du contenu"
        />
        <input
          type="datetime-local"
          name="content.date"
          value={postData['content.date']}
          onChange={handleChange}
          placeholder="Date du contenu"
        />
      <label>
        Type de média:
        <select name="content.media_type" value={postData['content.media_type']} onChange={handleChange}>
          <option value="">Sélectionner un type</option>
          <option value="photo">Photo</option>
          <option value="video">Vidéo</option>
          <option value="text">Texte</option>
        </select>
      </label>

      <label className="checkbox-container">
        Pièce jointe
        <input
          type="checkbox"
          name="isAttachment"
          checked={postData.isAttachment}
          onChange={handleChange}
        />
        <span className="checkmark"></span>
      </label>
        {postData.isAttachment && (
          <div
          name="attachment_file"
          className="drag-drop-zone"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, "attachment_file")}
          onClick={() => openFileDialog("fileInput")}
        >
          Drag & Drop your file here or click to select a file
          <input
            id="fileInput"
            name="attachment_file"
            type="file"
            onChange={handleChange}
            style={{ display: 'none' }} // Masquer l'input de fichier
          />
          {postData.attachment_file && <div>File: {postData.attachment_file.name}</div>}
        </div>
        )}
       
      </fieldset>

      <fieldset>
        <legend>Auteur:</legend>
        <input
        type="text"
        name="author_id"
        value={postData.author_id}
        onChange={handleChange}
        placeholder="ID de l'auteur"
        />
        <label className="checkbox-container">
        Créer l'auteur
        <input
          type="checkbox"
          name="newAuthor"
          checked={postData.newAuthor}
          onChange={handleChange}
          />
        <span className="checkmark"></span>
      </label>
        
        {postData.newAuthor && (

          <div>
            <input type="text" name="author_info.name" value={postData['author_info.name']} onChange={handleChange} placeholder="Nom de l'auteur" />
            <div
              name="profile_picture_file"
              className="drag-drop-zone"
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, "profile_picture_file")}
              onClick={() => openFileDialog("profile_picture_file_input")}
            >
              Choisir photo de profil
              <input
                id="profile_picture_file_input"
                name="profile_picture_file"
                type="file"
                onChange={handleChange}
                style={{ display: 'none' }} // Masquer l'input de fichier
              />
              {postData.profile_picture_file && <div>File: {postData.profile_picture_file.name}</div>}
            </div>

            <div
              name="background_picture_file"
              className="drag-drop-zone"
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, "background_picture_file")}
              onClick={() => openFileDialog("background_picture_file_input")}
            >
              Choisir photo de couverture
              <input
                id="background_picture_file_input"
                name="background_picture_file"
                type="file"
                onChange={handleChange}
                style={{ display: 'none' }} // Masquer l'input de fichier
              />
              {postData.background_picture_file && <div>File: {postData.background_picture_file.name}</div>}
            </div>
            
            
            <label className="checkbox-container">
              Profil certifié
              <input type="checkbox" name="author_info.is_certified" checked={postData['author_info.is_certified']} onChange={handleChange} />
              <span className="checkmark"></span>
            </label>
            <input type="number" name="author_info.account_since" value={postData['author_info.account_since']} onChange={handleChange} placeholder="Compte depuis (année)" />
            <input type="number" name="author_info.followers" value={postData['author_info.followers']} onChange={handleChange} placeholder="Nombre de followers" />
            <input type="number" name="author_info.following" value={postData['author_info.following']} onChange={handleChange} placeholder="Nombre de suivis" />
            <input type="text" name="author_info.owner" value={postData['author_info.owner']} onChange={handleChange} placeholder="Propriétaire" />
            <label className="checkbox-container">
              Accord de l'auteur
              <input type="checkbox" name="author_info.agreement" checked={postData['author_info.agreement']} onChange={handleChange} />
              <span className="checkmark"></span>
            </label>
            <input type="text" name="author_info.category" value={postData['author_info.category']} onChange={handleChange} placeholder="Catégorie" />
            <input type="text" name="author_info.profile_type" value={postData['author_info.profile_type']} onChange={handleChange} placeholder="Type de profil" />

            {/* Upload de fichiers */}
            {/* <input type="file" name="profile_picture_file" onChange={handleFileChange} placeholder="Photo de profil" />
            <input type="file" name="background_picture_file" onChange={handleFileChange} placeholder="Photo de couverture" /> */}

          </div>
        )}

      </fieldset>


      <input
        type="text"
        name="language"
        value={postData.language}
        onChange={handleChange}
        placeholder="Langue"
      />
      <fieldset>
        <legend>Réactions:</legend>
        <input
          type="number"
          name="reaction.comment_count"
          value={postData['reaction.comment_count']}
          onChange={handleChange}
          placeholder="Nombre de commentaires"
        />
        <input
          type="number"
          name="reaction.like_count"
          value={postData['reaction.like_count']}
          onChange={handleChange}
          placeholder="Nombre de likes"
        />
        <input
          type="number"
          name="reaction.view_count"
          value={postData['reaction.view_count']}
          onChange={handleChange}
          placeholder="Nombre de vues"
        />
        <input
          type="number"
          name="reaction.repost_count"
          value={postData['reaction.repost_count']}
          onChange={handleChange}
          placeholder="Nombre de reposts"
        />
      </fieldset>
      <input
        type="text"
        name="owner"
        value={postData.owner}
        onChange={handleChange}
        placeholder="Propriétaire"
      />
      <input
        type="text"
        name="theme"
        value={postData.theme}
        onChange={handleChange}
        placeholder="Thème"
      />
      <fieldset>
        <legend>Source:</legend>
        <input
          type="text"
          name="source.explanation"
          value={postData['source.explanation']}
          onChange={handleChange}
          placeholder="Explication"
        />
        <input
          type="text"
          name="source.know_more"
          value={postData['source.know_more']}
          onChange={handleChange}
          placeholder="En savoir plus"
        />
        <input
          type="text"
          name="source.url"
          value={postData['source.url']}
          onChange={handleChange}
          placeholder="URL de la source"
        />

        <div
          name="source_illustration_file"
          className="drag-drop-zone"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, "source_illustration_file")}
          onClick={() => openFileDialog("source_illustration_filee_input")}
        >
          Choisir image d'illustration
          <input
            id="source_illustration_file_input"
            name="source_illustration_file"
            type="file"
            onChange={handleChange}
            style={{ display: 'none' }} // Masquer l'input de fichier
          />
          {postData.source_illustration_file && <div>File: {postData.source_illustration_file.name}</div>}
        </div>
        <label>Probabilité
        <input
          type="range"
          name="source.probability"
          value={postData['source.probability']}
          onChange={handleChange}
          min="1"
          max="10"
          placeholder="Probabilité"
        />
        </label>

      </fieldset>
      <label>
        Masqué:
        <input
          type="checkbox"
          name="masked"
          checked={postData.masked}
          onChange={handleChange}
        />
      </label>
      <button type="submit">Soumettre</button>
    </form>
  );
}

export default FormNewTweet;
