import { Fragment, useEffect, useState } from "react";

import formatNumber from "./utils/number_utils";


function ProfileBanner({profileId}) {
    const [dataProfile, setDataProfile] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchProfile() {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/profile/'+profileId);
                if (response.ok) {
                    const data = await response.json();
                    //setDataProfile(data);
                    ProcessData(data);
                    setLoading(false);
                } else {
                    console.error('Failed to fetch posts');
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        }
        setLoading(true);
        fetchProfile();
    },
    
    []);
    function ProcessData(data){
        var newDataProfile = data;
        if (!data.profile_picture_url) {
            newDataProfile = { ...dataProfile, profile_picture_url: "https://res.cloudinary.com/ddla464hf/image/upload/v1707749324/Design_sans_titre_1_bh4iwa.jpg"};
        }
        if (!data.background_picture_url) {
            newDataProfile = { ...newDataProfile, background_picture_url: "https://res.cloudinary.com/ddla464hf/image/upload/v1707748979/Design_sans_titre_iis0fe.jpg" };
        }
        setDataProfile(newDataProfile);
        console.log(dataProfile)
    }

    return (
        <Fragment>
        {loading ? (
          <div className="loader-container">
            <img src="./loader.gif" className="loader" />
          </div>
        ) : (
        <div>
            <div className="profile_container">
                <div className="profile_bg_picture_container">
                    <img className="profile_bg_picture" src={dataProfile.background_picture_url} onError={(e) => { e.target.onerror = null; e.target.src = '/unknown_background_picture.jpg'; }}></img>
                </div>
                <div className="profile_info_container">
                    <div className="profile_info_upper_part">
                        {/* <div className="test">
                            
                        </div> */}
                        <div className="profile_picture_container">
                            <div className="profile_picture_border">
                                <img className="profile_picture" src={dataProfile.profile_picture_url} onError={(e) => { e.target.onerror = null; e.target.src = '/unkown_profile_picture.jpg'; }}></img>
                            </div>
                        
                        </div>
                        
                        <div className="subscription_container">
                            <div className="show_more_button">...</div>
                            <div className="follow_button">Suivre</div>
                        </div>

                    </div>
                    <div className="profile_info_lower_part">
                        <div className="account_name_container">
                            <div className="name_container">
                                <div className="name">{dataProfile.name}</div>
                                <div>{dataProfile.is_certified && <svg viewBox="0 0 22 22" aria-label="Compte certifié" role="img" class="verified_icon" data-testid="icon-verified"><g><path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path></g></svg>}</div>
                            </div>
                            <div className="username_container">
                                <div className="username">@{dataProfile._id}</div>
                            </div>
                        </div>
                        <div className="account_since_container">
                            <div className="calendar_icon_container">
                            <svg viewBox="0 0 24 24" aria-hidden="true" className="calendar_icon"><g><path d="M7 4V3h2v1h6V3h2v1h1.5C19.89 4 21 5.12 21 6.5v12c0 1.38-1.11 2.5-2.5 2.5h-13C4.12 21 3 19.88 3 18.5v-12C3 5.12 4.12 4 5.5 4H7zm0 2H5.5c-.27 0-.5.22-.5.5v12c0 .28.23.5.5.5h13c.28 0 .5-.22.5-.5v-12c0-.28-.22-.5-.5-.5H17v1h-2V6H9v1H7V6zm0 6h2v-2H7v2zm0 4h2v-2H7v2zm4-4h2v-2h-2v2zm0 4h2v-2h-2v2zm4-4h2v-2h-2v2z"></path></g></svg>
                            </div>
                            <div className="account_since">A rejoint Get Ready en {dataProfile.account_since}</div>
                        </div>
                        <div className="account_metrics_container">
                            <div className="metric">
                                <span className="black_metric"><b>{formatNumber(dataProfile.following)}</b></span>
                                <span className="metric_value ">abonnements</span>
                            </div>
                            <div className="metric">
                                <span className="black_metric"><b>{formatNumber(dataProfile.followers)}</b></span>
                                <span className="metric_value">abonnés</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        )}
    </Fragment>
    )
}

export default ProfileBanner;