import React from "react";





export const highlightText = (text) => {
    const regex_tag = /@\p{L}[\p{L}\p{N}_]*/gu;
    const regex_hashtag = /#\p{L}[\p{L}\p{N}_]*/gu;
    const regex_newLine = /\n/g;
    
    let formattedText = text.replace(regex_tag, `%%%$&%%%`)
                             .replace(regex_hashtag, `%%%$&%%%`)
                             .replace(regex_newLine, `%%%\\n%%%`);

    const parts = formattedText.split("%%%");

    return parts.map((part, index) => {
        if (regex_hashtag.test(part)) {
            return <a key={index} className="hashtag_text" href="#">{part}</a>;
        } else if (regex_tag.test(part)) {
            return <a key={index} className="mention_text" href="#">{part}</a>;
        } else if (part === '\\n') {
            return <br key={index} />;
        } else {
            return part;
        }
    });
};


export const breaklineText = (text) => {
    const regex_newLine = /\n/g;
    
    let formattedText = text.replace(regex_newLine, `%%%\\n%%%`);     

    const parts = formattedText.split("%%%");

    return parts.map((part, index) => {
        if (part === '\\n') {
            return <br key={index} />;
        } else {
            return part;
        }
    });
};



// const highlightText = (text) => {
//     // const regex_tag = /@\w+/g;
//     // const regex_hashtag = /#\w+/g;
//     const regex_tag = /@\p{L}[\p{L}\p{N}_]*/gu;
//     const regex_hashtag = /#\p{L}[\p{L}\p{N}_]*/gu;
//     const matches_tag = text.match(regex_tag);
//     const matches_hashtag = text.match(regex_hashtag);

//     //console.log(matches_hashtag)
//     //console.log(matches_tag)

//     let formattedText = text;

//     if (matches_tag) {
//         matches_tag.forEach((match) => {
//         formattedText = formattedText.replace(match, `%%%${match}%%%`);
//         });
//     }

//     if (matches_hashtag) {
//         matches_hashtag.forEach((match) => {
//         formattedText = formattedText.replace(match, `%%%${match}%%%`);
//         });
//     }

//     const parts = formattedText.split("%%%");
//     return parts.map((part, index) => {
//         // console.log(part) 
//         if (matches_hashtag !== null && matches_hashtag.includes(part)) {
//             // return React.createElement('a', { key: index, className:"hashtag_text", href:"/hashtag/"+part.replace("#","") }, part);
//             return React.createElement('a', { key: index, className:"hashtag_text", href:"#" }, part);

//         } else if (matches_tag !== null && matches_tag.includes(part)) {
//             // return React.createElement('a', { key: index, className:"mention_text", href:"/profile/"+part.replace("@","") }, part);
//             return React.createElement('a', { key: index, className:"mention_text", href:"#" }, part);
//         } else {
//             return part;
//         }
//     });
// };




//export default highlightText;