import React, { Fragment, useEffect, useRef, useState } from "react";
import {highlightText, breaklineText} from "./utils/text_utils";
import formatNumber from "./utils/number_utils";
import formatDate from "./utils/date_utils";
import { useNavigate, useParams } from "react-router-dom";
import Gauge from "./Gauge";




function Post({PostId, PostContent, PostAuthor, PostMetrics, PostSource}) {
    const navigate = useNavigate();

    const { text, date, is_attachment, media_type, media_url } = PostContent;
    const { profile_picture_url, _id, name, is_certified } = PostAuthor;

    const [deploy, setDeploy] = useState(false);
    const arrowRotation = deploy ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' };

    const truncateUrl = (url) => {
        return url.length > 100 ? url.substring(0, 100) + "..." : url;
      };
    
    // Gestionnaire de clic pour ouvrir l'URL dans un nouvel onglet
    const handleClickSource = (url_to_open) => {
    window.open(url_to_open, '_blank', 'noopener,noreferrer');
    };
  
    return (
      <div className="post">
        <div className="author_picture_section">
            <a href={"/profile/"+_id}><img src={profile_picture_url} className="author_profile_picture" alt="Profile" onError={(e) => { e.target.onerror = null; e.target.src = '/unkown_profile_picture.jpg'; }}/></a>
        </div>
        <div className="post_content_container">
            <div className="post_header">
                <div className="author">
                    <div className="author_header">
                        <a href={"/profile/"+_id}><span className="name">{name}</span></a>
                        <div>{is_certified && <svg viewBox="0 0 22 22" aria-label="Compte certifié" role="img" class="verified_icon" data-testid="icon-verified"><g><path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path></g></svg>}</div>
                    </div>
                    <div className="author_footer">
                    
                    <a href={"/profile/"+_id}><span className="username">@{_id}</span></a>
                        <span className="separator">·</span>
                        <span className="date">
                            <time dateTime={date}>{formatDate(date)}</time>
                        </span>
                    </div>
                </div>
                <span className="see_more">···</span>
            </div>
            
            <div className="content" onClick={()=>{navigate('/post/'+PostId)}}>
                <p className="text">{highlightText(text)}</p>
                {is_attachment && (
                    <div className="media">
                    
                    {media_type === "photo" && (
                    <img src={media_url} alt="Media" />
                    )}
                    {media_type === "video" && (
                    <video controls>
                        <source src={media_url} />
                        Your browser does not support the video tag.
                    </video>
                    )}
                    {/* Ajoutez d'autres conditions pour d'autres types de médias si nécessaire */}
                    </div>
                )}
            </div>

            <div className="metrics_container">
                <div className="metric">
                <svg viewBox="0 0 24 24" aria-hidden="true" class="metric_logo view_logo"><g><path d="M1.751 10c0-4.42 3.584-8 8.005-8h4.366c4.49 0 8.129 3.64 8.129 8.13 0 2.96-1.607 5.68-4.196 7.11l-8.054 4.46v-3.69h-.067c-4.49.1-8.183-3.51-8.183-8.01zm8.005-6c-3.317 0-6.005 2.69-6.005 6 0 3.37 2.77 6.08 6.138 6.01l.351-.01h1.761v2.3l5.087-2.81c1.951-1.08 3.163-3.13 3.163-5.36 0-3.39-2.744-6.13-6.129-6.13H9.756z"></path></g></svg>                    
                <div className="metric_value">{formatNumber(PostMetrics.comment_count)}</div>
                </div>
                <div className="metric">
                <svg viewBox="0 0 24 24" aria-hidden="true" class="metric_logo share_logo"><g><path d="M4.5 3.88l4.432 4.14-1.364 1.46L5.5 7.55V16c0 1.1.896 2 2 2H13v2H7.5c-2.209 0-4-1.79-4-4V7.55L1.432 9.48.068 8.02 4.5 3.88zM16.5 6H11V4h5.5c2.209 0 4 1.79 4 4v8.45l2.068-1.93 1.364 1.46-4.432 4.14-4.432-4.14 1.364-1.46 2.068 1.93V8c0-1.1-.896-2-2-2z"></path></g></svg>                    
                <div className="metric_value">{formatNumber(PostMetrics.repost_count)}</div>
                </div>
                <div className="metric">
                <svg viewBox="0 0 24 24" aria-hidden="true" class="metric_logo like_logo"><g><path d="M16.697 5.5c-1.222-.06-2.679.51-3.89 2.16l-.805 1.09-.806-1.09C9.984 6.01 8.526 5.44 7.304 5.5c-1.243.07-2.349.78-2.91 1.91-.552 1.12-.633 2.78.479 4.82 1.074 1.97 3.257 4.27 7.129 6.61 3.87-2.34 6.052-4.64 7.126-6.61 1.111-2.04 1.03-3.7.477-4.82-.561-1.13-1.666-1.84-2.908-1.91zm4.187 7.69c-1.351 2.48-4.001 5.12-8.379 7.67l-.503.3-.504-.3c-4.379-2.55-7.029-5.19-8.382-7.67-1.36-2.5-1.41-4.86-.514-6.67.887-1.79 2.647-2.91 4.601-3.01 1.651-.09 3.368.56 4.798 2.01 1.429-1.45 3.146-2.1 4.796-2.01 1.954.1 3.714 1.22 4.601 3.01.896 1.81.846 4.17-.514 6.67z"></path></g></svg>                    
                <div className="metric_value">{formatNumber(PostMetrics.like_count)}</div>
                </div>
                <div className="metric">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="metric_logo view_logo"><g><path d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path></g></svg>
                    <div className="metric_value">{formatNumber(PostMetrics.view_count)}</div>
                </div>
                <div className="metric"><svg viewBox="0 0 24 24" aria-hidden="true" class="metric_logo view_logo"><g><path d="M12 2.59l5.7 5.7-1.41 1.42L13 6.41V16h-2V6.41l-3.3 3.3-1.41-1.42L12 2.59zM21 15l-.02 3.51c0 1.38-1.12 2.49-2.5 2.49H5.5C4.11 21 3 19.88 3 18.5V15h2v3.5c0 .28.22.5.5.5h12.98c.28 0 .5-.22.5-.5L19 15h2z"></path></g></svg></div>
            </div>

            <div className="post_context_section">
                <div className="about_information_container" onClick={() => setDeploy(!deploy)}>
                    <div className="about_information_dropdown">
                        <img className="arrow_icon" src="/bottom_arrow.png" style={arrowRotation} />
                        <div className="about_information_title">À propos de cette information</div>
                    </div>
                    <div>
                        <img className="info_icon" src="/info_icon.png"/>
                    </div>
                </div>
                {deploy &&
                (<Fragment>
                    <div className="source_container">
                        <div className="probability_container"><Gauge score={PostSource.probability} /></div>
                        <div className="know_more_section">
                            <a href="https://www.linkedin.com/company/hydros-conseil/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="author_info_title_container">
                                <svg height="18px" width="18px" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#ff6464" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
                                    <path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/>
                                    </g>
                                </svg>
                                <div className="know_more_text">À propos de l'auteur</div>
                                </div>
                                <div className="author_info">
                                <div className="author_image_container">
                                    <img className="author_image_icon" src="/logo_hydros.png"/>
                                </div>
                                <div className="author_name">Hydros</div>
                                </div>
                            </a>
                            </div>

                    </div>

                    <div className="explanation_section">
                       {PostSource.explanation && (
                        <div>
                            <div className="explanation_title">L'explication</div>
                            <div className="explanation_text">{breaklineText(PostSource.explanation)}</div>
                        </div>)}

                        {PostSource.know_more && (
                        <div>
                            <div className="explanation_title">En savoir plus</div>
                            <div className="explanation_text">{breaklineText(PostSource.know_more)}</div>
                        </div>)}

                        {PostSource.illustration && (
                        <div className="source_illustration">
                            <img src={PostSource.illustration}></img>
                        </div>)}

                        {PostSource.url && (
                            <div>
                                <div className="explanation_title">Les sources</div>
                                <div className="source_item" onClick={() => {handleClickSource(PostSource.url)}}>
                                    <svg className="source_icon" viewBox="0,0,256,256"><g fill="#ff6464"><g transform="scale(10.66667,10.66667)"><path d="M3,3v18h18v-9h-2v7h-14v-14h7v-2zM14,3v2h3.58594l-9.29297,9.29297l1.41406,1.41406l9.29297,-9.29297v3.58594h2v-7z"/></g></g></svg>
                                    <div className="source_name">{truncateUrl(PostSource.url)}</div>
                                </div>
                            </div>)}

                    </div>          
                </Fragment>)}     
            
            </div>
      </div>
    </div>
    );
  };

export default Post;