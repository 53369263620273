import logo from './logo.svg';
import './App.css';
import "./assets/fonts/fonts.css";
import Feed from './components/Feed';
import Header from './components/Header';
import Banner from './components/Banner';
import Sidebar from './components/Sidebar';
import PathChoicerComponent from './components/PathChoicerComponent';
import TimelineComponent from './components/TimelineComponent';
import PostDetail from './components/PostDetail';
import ProfileBanner from './components/ProfileBanner';
import FormNewTweet from './components/FormNewTweet';
import FormNewProfile from './components/FormNewProfile';

import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useParams,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/explorer",
    element: <Explorer tag=""/>,
  },
  {
    path: "/explorer/webinar",
    element: <Explorer tag="webinar" />,
  },
  {
    path: "/pathchoice",
    element: <PathChoicer />,
  },
  {
    path: "/timeline",
    element: <TimelineChoicer />,
  },
  {
    path: "/post/:postId",
    element: <Post />,
  },
  {
    path: "/profile/:profileId",
    element: <Profile />,
  },
  {
    path: "/newpost",
    element: <NewPost />,
  },
  {
    path: "/newprofile",
    element: <NewProfile />,
  },
]);

function Explorer({ tag }) {
  // Définir le tag à utiliser pour filtrer les éléments

  return (
    <div className="App">
      <div className='global_container'>
        <Banner></Banner>
        <div className='main'>
          <div className='main_container'>
            <div className="feed">
              {/* Passer le tag en tant que propriété à Feed */}
              <Header></Header>
              <Feed tag={tag}></Feed>
            </div>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </div>
  );
}

function Profile() {
  const { profileId } = useParams();
  return (
    <div className="App">
      <div className='global_container'>
        <Banner></Banner>
        <div className='main'>
          <div className='main_container'>
            <div className="feed">
              <Header></Header>
              <ProfileBanner profileId={profileId}></ProfileBanner>
              {/* <Feed></Feed> */}
            </div>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </div>
  );
}

function Post() {
  const { postId } = useParams();

  return (
    <div className="App">
      <div className='global_container'>
        <Banner></Banner>
        <div className='main'>
          <div className='main_container'>
            <div className="feed">
              <Header></Header>
              <PostDetail postId = {postId}></PostDetail>
            </div>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewPost() {
  return (
    <FormNewTweet></FormNewTweet>
  )
}

function NewProfile() {
  return (
    <FormNewProfile></FormNewProfile>
  )
}

function PathChoicer() {
  return (
    <div className="App"> 
        <PathChoicerComponent></PathChoicerComponent>
    </div>
  )
}

function TimelineChoicer () {
  return (
    <div className='App'>
      <TimelineComponent></TimelineComponent>
    </div>
  )
}


function App() {
  return <RouterProvider router={router} />;
}

export default App;
