import React, { Fragment, useEffect, useState } from "react";
import Post from "./Post";
import InfiniteScroll from 'react-infinite-scroll-component';

function Feed({ tag }) {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const number = 5;

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/feed?start=${page}&number=${number}&tag=${tag}`);
      const data = await response.json();

      setItems(prevItems => [...prevItems, ...data]);
      setPage(prevPage => prevPage + number);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={true} // Replace with a condition based on your data source
        loader={<p>Loading...</p>}
        endMessage={<p>No more data to load.</p>}
      >
        {items.map((post) => (
          <Post
            key={post._id}
            PostId={post._id}
            PostContent={post.content}
            PostAuthor={post.author_info}
            PostMetrics={post.reaction}
            PostSource={post.source}
          />
        ))}
      </InfiniteScroll>
      {error && <p>Error: {error.message}</p>}
    </div>
  );
}

export default Feed;
