import { Fragment, useEffect, useState } from "react";


function Header() {
    return (
        <div className="header_container">
            <div className="header_title">
            Explorer
            </div>
            <div className="header_subtitle">
            Explorez un monde soumis à une situation de stress hydrique à horizon 2035 à travers ce réseau social fictif créé par Hydros
            </div>
            
        </div>
    )
}

export default Header