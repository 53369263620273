import { Fragment, useEffect, useState } from "react";


function PathChoicerComponent() {

    const RedirectPath = (param) => {
        window.location.href = window.location.origin + "/timeline?path="+param; // Replace with the desired URL
    };


    return (
        <div className="path-choicer-container">
            <h1 className="path-choicer-title">Choisissez votre scénario</h1>
            <p className="path-choicer-description">L’ADEME a souhaité soumettre au débat quatre chemins “types” cohérents qui présentent de manière volontairement contrastée des options économiques, techniques et de société pour atteindre la neutralité carbone en 2050. Imaginés pour la France métropolitaine, ils reposent sur les mêmes données macroéconomiques, démographiques et d’évolution climatique (+2,1 °C en 2100). Cependant, ils empruntent des voies distinctes et correspondent à des choix de société différents.</p>
            <div className="path-choicer-blocks">
                <div className="path-choicer-block"  onClick={() => RedirectPath("generation-frugale")}>
                <img src="https://res.cloudinary.com/dc1felxem/image/upload/v1687011131/illustration-generation-frugale-2022_ae7a58.webp" alt="Image 1" className="path-choicer-image" />
                <div className="container-choicer-title">Génération Frugale</div>
                <p className="container-choicer-text">La transition est conduite principalement par la contrainte et par la sobriété. </p>
                </div>

                <div className="path-choicer-block" onClick={() => RedirectPath("cooperation-territoriale")}>
                <img src="https://res.cloudinary.com/dc1felxem/image/upload/v1687011104/illustration-cooperation-territoriale-2022_b3p8ac.webp" alt="Image 2" className="path-choicer-image" />
                <div className="container-choicer-title">Coopérations territoriales</div>
                <p className="container-choicer-text">La société se transforme dans le cadre d’une gouvernance partagée.</p>
                </div>

                <div className="path-choicer-block" onClick={() => RedirectPath("technologie-verte")}>
                <img src="https://res.cloudinary.com/dc1felxem/image/upload/v1687011105/illustration-technologie-verte-2022_ls74en.webp" alt="Image 3" className="path-choicer-image" />
                <div className="container-choicer-title">Technologies vertes</div>
                <p className="container-choicer-text">L’innovation est mise au service de systèmes énergétiques décarbonés.</p>
                </div>

                <div className="path-choicer-block" onClick={() => RedirectPath("pari-reparateur")}>
                <img src="https://res.cloudinary.com/dc1felxem/image/upload/v1687011100/illustration-pari-reparateur-2022_vyksuq.webp" alt="Image 3" className="path-choicer-image" />
                <div className="container-choicer-title">Pari réparateur</div>
                <p className="container-choicer-text">La société place sa confiance dans la capacité à réparer, les systèmes sociaux et écologiques.	</p>
                </div>
            </div>
        </div>
    )
}

export default PathChoicerComponent