import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import Spline from '@splinetool/react-spline';


const TimelineComponent = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  console.log(searchParams)
  const pathValue = searchParams.get('path');
  console.log(pathValue)

  

  const RedirectExplorer = (period) => {

    window.location.href = window.location.origin + "/explorer?path="+pathValue+"&period="+period; // Replace with the desired URL
  }


  const [scrollPercentage, setScrollPercentage] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const percentage = (scrollTop / maxScroll) * 100;
      setScrollPercentage(percentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getBackgroundColor = () => {
    const green = 120 - scrollPercentage * 0.8;
    const red = scrollPercentage * 0.8;
    return `rgb(${red}, ${green}, 0)`;
  };

  const getCounterValue = () => {
    const startYear = 2023;
    const endYear = 2100;
    const scrollRange = endYear - startYear;
    const counterValue = Math.round((scrollPercentage * scrollRange) / 100) + startYear;
    return counterValue;
  };

  const getCounterPeriod = () => {
    const counterValue = getCounterValue();
    if (counterValue < 2030) {
      return "2023-2030"
    } else if (counterValue <= 2040) {
      return "2030-2040"
    } else if (counterValue <= 2050) {
      return "2040-2050"
    } else if (counterValue <= 2070) {
      return "2050-2070"
    } else if (counterValue <= 2100) {
      return "2070-2100"
    }
  }

  const getGraduationPosition = (offset) => {
    const freq = 0.1;
    const value = (scrollPercentage+offset)%10-5;
    //const amplitude = window.innerHeight + 30;
    const amplitude = window.innerHeight/4;
    var position = 0;
    position = -amplitude*value
    return position;
  };


  return (
    <div className='global-timeline-body'>
      <div className="scroll-page" style={{ backgroundColor: getBackgroundColor() }}>
        <div className="scroll-counter">{getCounterValue()}</div>
        
        <button className="button-counter" onClick={() => RedirectExplorer(getCounterPeriod())}>Explorer cette période</button>
        <div className="scroll-counter-period">{getCounterPeriod()}</div>

        {/* <div>
          <Spline scene="https://prod.spline.design/M9OuW8x8W9gtP5Qv/scene.splinecode" />
        </div> */}


        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(-1) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(-1) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(-2) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(-2) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(-3) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(-3) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(-4) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(-4) }}></div>

        <div className="graduation-left" style={{ marginTop: getGraduationPosition(0) }}></div>
        <div className="graduation-right" style={{ marginTop: getGraduationPosition(0) }}></div>

        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(1) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(1) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(2) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(2) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(3) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(3) }}></div>
        <div className="small-graduation-left" style={{ marginTop: getGraduationPosition(4) }}></div>
        <div className="small-graduation-right" style={{ marginTop: getGraduationPosition(4) }}></div>
        
        <div className="graduation-left" style={{ marginTop: getGraduationPosition(5) }}></div>
        <div className="graduation-right" style={{ marginTop: getGraduationPosition(5) }}></div>
        
      </div>
    </div>
  );
};

export default TimelineComponent;
