import React, { useState } from 'react';


const Gauge = ({ score }) => {
  const [isHovered, setIsHovered] = useState(false);

  const getBackgroundColor = (score) => {
    if (score <= 50) {
      const redToYellowRatio = score / 50;
      const green = Math.round(255 * redToYellowRatio);
      return `rgb(255,${green},0)`;
    } else {
      const yellowToGreenRatio = (score - 50) / 50;
      const red = 255 - Math.round(255 * yellowToGreenRatio);
      return `rgb(${red},255,0)`;
    }
  };

  const getProbabilityText = (score) => {
    if (score >= 70) return "Haute";
    if (score >= 30) return "Moyenne";
    return "Basse";
  };

  const filledWidth = `${score}%`;

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
    <div className="gauge-container">
      <div
        className="gauge-filled"
        style={{
          width: filledWidth,
          backgroundColor: getBackgroundColor(score),
          borderRadius: '50px',
        }}
      />
      {isHovered && <div className="gauge-tooltip">Degré de confiance: {score}/100</div>} 
    </div>
    <div className="gauge-probability">Probabilité : {getProbabilityText(score)}</div>
    </div>
  );
};

export default Gauge;
