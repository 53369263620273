// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Chirp-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Chirp-Heavy.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Chirp-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Chirp-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Chirp";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
    /* Ajoutez d'autres formats de police si nécessaire */
    font-weight: bold;
  }

@font-face {
    font-family: "Chirp";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
    /* Ajoutez d'autres formats de police si nécessaire */
    font-weight: bolder;
  }

@font-face {
    font-family: "Chirp";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
    /* Ajoutez d'autres formats de police si nécessaire */
    font-weight: normal;
  }

@font-face {
    font-family: "Chirp";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
    /* Ajoutez d'autres formats de police si nécessaire */
    font-weight: light;
  }`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,4DAA4C;IAC5C,qDAAqD;IACrD,iBAAiB;EACnB;;AAEF;IACI,oBAAoB;IACpB,4DAA6C;IAC7C,qDAAqD;IACrD,mBAAmB;EACrB;;AAEF;IACI,oBAAoB;IACpB,4DAA8C;IAC9C,qDAAqD;IACrD,mBAAmB;EACrB;;AAEF;IACI,oBAAoB;IACpB,4DAA+C;IAC/C,qDAAqD;IACrD,kBAAkB;EACpB","sourcesContent":["@font-face {\n    font-family: \"Chirp\";\n    src: url(\"Chirp-Bold.woff2\") format(\"woff2\");\n    /* Ajoutez d'autres formats de police si nécessaire */\n    font-weight: bold;\n  }\n\n@font-face {\n    font-family: \"Chirp\";\n    src: url(\"Chirp-Heavy.woff2\") format(\"woff2\");\n    /* Ajoutez d'autres formats de police si nécessaire */\n    font-weight: bolder;\n  }\n\n@font-face {\n    font-family: \"Chirp\";\n    src: url(\"Chirp-Medium.woff2\") format(\"woff2\");\n    /* Ajoutez d'autres formats de police si nécessaire */\n    font-weight: normal;\n  }\n\n@font-face {\n    font-family: \"Chirp\";\n    src: url(\"Chirp-Regular.woff2\") format(\"woff2\");\n    /* Ajoutez d'autres formats de police si nécessaire */\n    font-weight: light;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
