function formatNumber(number) {
    if (number < 10000) {
      return number.toString(); // Retourne simplement le nombre s'il est inférieur à 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(0) + ' k'; // Raccourcit à 1 décimale et ajoute 'K' s'il est entre 1000 et 999999
    } else {
      return (number / 1000000).toFixed(0) + ' M'; // Raccourcit à 1 décimale et ajoute 'M' s'il est supérieur ou égal à 1000000
    }
  }


export default formatNumber;
