import React, { useState, useCallback, useEffect } from 'react';
import '../form.css';

function FormNewProfile() {
  const [dataInit, setDataInit] = useState();
  const [postData, setPostData] = useState({
    owner: '',
    author_id: '',
    'author_info': {
      name: '',
      fake_id: '', // Nouveau champ
      fake_name: '', // Nouveau champ
      is_certified: false,
      account_since: 2011,
      followers: 1000,
      following: 100,
      owner: '',
      agreement: false,
      category: '',
      profile_type: '',
    },
    profile_picture_file: null,
    background_picture_file: null,
    source_illustration_file: null,
  });



  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (files) {
      setPostData({ ...postData, [name]: files[0] });
    } else {
      if (name in postData || name.startsWith('author_info.')) {
        const [field, subfield] = name.split('.');
        if (subfield) {
          setPostData({
            ...postData,
            [field]: { ...postData[field], [subfield]: value },
          });
        } else {
          if (type === 'checkbox') {
            setPostData({ ...postData, [name]: checked });
          } else {
            setPostData({ ...postData, [name]: value });
          }
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('profile_picture_file', postData.profile_picture_file)
    formData.append('background_picture_file', postData.background_picture_file)


    
    const appendFormData = (data, parentKey = '') => {
        Object.keys(data).forEach(key => {
          // Vérifiez si la propriété est un objet
          if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null && key !== 'file') {
            appendFormData(data[key], parentKey + key + '.');
          } else {
            // Ajoutez directement les valeurs simples ou convertissez les tableaux/objets en JSON
            formData.append(parentKey + key, typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]);
          }
        });
    };

    appendFormData(postData);

    // Ajoutez les autres champs de postData à formData si nécessaire
    if (postData.isAttachment && postData.file) {
      formData.append('file', postData.file);
    }
    // Envoyez formData à votre API
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create_profile`, {
        method: 'POST',
        body: formData, // Pas besoin de spécifier 'Content-Type' ici; FormData le fait pour vous
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      console.log(data); // Gérer la réponse ici
    } catch (error) {
      console.error('Erreur lors de la soumission:', error);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault(); // Nécessaire pour permettre le drop
  };

  const onDrop= (e, key) => {
    console.log(key)
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setPostData({ ...postData, [key]: files[0] }); 
    }
  };


  const openFileDialog = (elem_key) => {
    document.getElementById(elem_key).click();
  };





  return (
    <form onSubmit={handleSubmit}>
      
      <fieldset>
        <legend>Auteur:</legend>
        <input
        type="text"
        name="author_id"
        value={postData.author_id}
        onChange={handleChange}
        placeholder="ID de l'auteur"
        />
       

        <input type="text" name="author_info.name" value={postData['author_info.name']} onChange={handleChange} placeholder="Nom de l'auteur" />
        {/* Ajout des nouveaux champs dans author_info */}
        <input type="text" name="author_info.fake_id" value={postData.author_info.fake_id} onChange={handleChange} placeholder="Fake ID" />
        <input type="text" name="author_info.fake_name" value={postData.author_info.fake_name} onChange={handleChange} placeholder="Fake Nom" />

        <div
          name="profile_picture_file"
          className="drag-drop-zone"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, "profile_picture_file")}
          onClick={() => openFileDialog("profile_picture_file_input")}
        >
          Choisir photo de profil
          <input
            id="profile_picture_file_input"
            name="profile_picture_file"
            type="file"
            onChange={handleChange}
            style={{ display: 'none' }} // Masquer l'input de fichier
          />
          {postData.profile_picture_file && <div>File: {postData.profile_picture_file.name}</div>}
        </div>

        <div
          name="background_picture_file"
          className="drag-drop-zone"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, "background_picture_file")}
          onClick={() => openFileDialog("background_picture_file_input")}
        >
          Choisir photo de couverture
          <input
            id="background_picture_file_input"
            name="background_picture_file"
            type="file"
            onChange={handleChange}
            style={{ display: 'none' }} // Masquer l'input de fichier
          />
          {postData.background_picture_file && <div>File: {postData.background_picture_file.name}</div>}
        </div>
        
        
        <label className="checkbox-container">
          Profil certifié
          <input type="checkbox" name="author_info.is_certified" checked={postData['author_info.is_certified']} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <input type="number" name="author_info.account_since" value={postData['author_info.account_since']} onChange={handleChange} placeholder="Compte depuis (année)" />
        <input type="number" name="author_info.followers" value={postData['author_info.followers']} onChange={handleChange} placeholder="Nombre de followers" />
        <input type="number" name="author_info.following" value={postData['author_info.following']} onChange={handleChange} placeholder="Nombre de suivis" />
        <input type="text" name="author_info.owner" value={postData['author_info.owner']} onChange={handleChange} placeholder="Propriétaire" />
        <label className="checkbox-container">
          Accord de l'auteur
          <input type="checkbox" name="author_info.agreement" checked={postData['author_info.agreement']} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <input type="text" name="author_info.category" value={postData['author_info.category']} onChange={handleChange} placeholder="Catégorie" />
        <input type="text" name="author_info.profile_type" value={postData['author_info.profile_type']} onChange={handleChange} placeholder="Type de profil" />

        {/* Upload de fichiers */}
        {/* <input type="file" name="profile_picture_file" onChange={handleFileChange} placeholder="Photo de profil" />
        <input type="file" name="background_picture_file" onChange={handleFileChange} placeholder="Photo de couverture" /> */}



      </fieldset>

      <button type="submit">Soumettre</button>
    </form>
  );
}

export default FormNewProfile;
